import { gql } from '@apollo/client';
import { userFragments } from '../graphql/amUserFragments';

export const userMutations = {
  LOGOUT_USER_MUTATION: gql`
    mutation {
      logoutUser
    }
  `,
  CREATE_USER_MUTATION: gql`
    mutation CreateUser($userData: CreateUserInput!) {
      createUser(data: $userData) {
        user {
          ...UserFields
        }
        token
      }
    }
    ${userFragments.userFields}
  `,
  UPDATE_USER_MUTATION: gql`
    mutation UpdateUser($userId: ID!, $userData: UpdateUserInput!) {
      updateUser(user_id: $userId, data: $userData) {
        ...UserFields
      }
    }
    ${userFragments.userFields}
  `,
  DELETE_USER_MUTATION: gql`
    mutation DeleteUser($userId: ID!) {
      deleteUser(user_id: $userId)
    }
  `,
  LOGIN_MUTATION: gql`
    mutation LoginUser($input: LoginInput!) {
      loginUser(data: $input) {
        token
        user {
          ...UserFields
        }
      }
    }
    ${userFragments.userFields}
  `
};
