import { jwtDecode } from 'jwt-decode';
import { logout, loginFailure } from '../modules/AccessManagement/redux/amSlice';

// Function to check token expiry
export const checkTokenExpiry = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp < currentTime;
};

const tokenMiddleware = ({ dispatch }) => (next) => (action) => {
  if (action.type === 'LOGIN_REQUEST' || action.type === 'LOGIN_SUCCESS' || action.type === 'LOGIN_FAILURE') {
    return next(action);
  }

  if (action.type.startsWith('@@redux-api') && localStorage.getItem('token')) {
    const token = localStorage.getItem('token');

    if (checkTokenExpiry(token)) {
      localStorage.removeItem('token');
      dispatch(logout());
      dispatch(loginFailure('Session expired, please login again'));
      return;
    }

    action.payload.headers = {
      ...action.payload.headers,
      Authorization: `Bearer ${token}`,
    };

    return next(action).catch((error) => {
      if (error.response && error.response.status === 401) {
        if (error.response.data.error === 'Invalid token' || error.response.data.error === 'Session expired, please login again') {
          localStorage.removeItem('token');
          dispatch(logout());
          dispatch(loginFailure('Session expired, please login again'));
        }
      }
      throw error;
    });
  }

  return next(action);
};

export default tokenMiddleware;
