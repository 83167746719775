import { gql } from '@apollo/client';

export const GET_USER_MENUS = gql`
  query GetUserMenus($roleCodes: [String!]!) {
    getUserMenus(roleCodes: $roleCodes) 
  }
`;





// import { gql } from '@apollo/client';
// import { moduleFragments } from './amUserMenuFragments';

// export const GET_USER_MENUS = gql`
//   query GetUserMenus($roleCode: String!) {
//     getUserMenus(roleCode: $roleCode) {
//       ...ModuleFields
//     }
//   }
//   ${moduleFragments.moduleFields}
// `;
