import { Routes, Route } from 'react-router-dom';

import Login from '../modules/AccessManagement/Authentication/components/LoginPage';


export default function AuthRouter() {

  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route element={<Login />} path="/login" />
    </Routes>
  );
}
