import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    unreadCount: 0,
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.unreadCount = action.payload.filter((notification) => notification.status !== 'read').length;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    decrementUnreadCount: (state) => {
      if (state.unreadCount > 0) {
        state.unreadCount -= 1;
      }
    },
    markNotificationAsRead: (state, action) => {
      const notificationId = action.payload;
      const notification = state.notifications.find((n) => n.notification_id === notificationId);
      if (notification && notification.status !== 'read') {
        notification.status = 'read';
        if (state.unreadCount > 0) {
          state.unreadCount -= 1;
        }
      }
    },
  },
});

export const { setNotifications, setUnreadCount, decrementUnreadCount, markNotificationAsRead } = notificationSlice.actions;

export default notificationSlice.reducer;