import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('token') || null,
  user: JSON.parse(localStorage.getItem('user')) || null,
  menuData: [],
  loading: false,
  error: null,
  showLoginModal: false,
  activeMenuItem: null, // Track the active menu item
};

const amSlice = createSlice({
  name: 'AccessManagement',
  initialState,
  reducers: {
    loginRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.loading = false;
      state.showLoginModal = false;
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('isLoggedIn', true);
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.showLoginModal = true;
      localStorage.setItem('isLoggedIn', false);
    },
    logout(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.setItem('isLoggedIn', false);
      state.token = null;
      state.user = null;
      state.menuData = [];
      state.showLoginModal = true;
    },
    setMenuData(state, action) { 
      state.menuData = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setActiveMenuItem(state, action) {
      state.activeMenuItem = action.payload;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout, setMenuData, setToken, setActiveMenuItem } = amSlice.actions;

export default amSlice.reducer;
