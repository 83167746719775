import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeMenuItem: 'home',
    menuData: [], // Add menuData to initial state
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setActiveMenuItem: (state, action) => {
            state.activeMenuItem = action.payload;
        },
        setMenuData: (state, action) => {
            state.menuData = action.payload; // Action to set menu data
        },
    },
});

export const { setActiveMenuItem, setMenuData } = menuSlice.actions;
export default menuSlice.reducer;
