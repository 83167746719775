import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { closeStatus } from '../../redux/slices/statusSlice';

const StatusDisplay = () => {
    const dispatch = useDispatch();
    const { message, severity, open } = useSelector((state) => state.status);

    const handleClose = () => {
        dispatch(closeStatus());
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default StatusDisplay;
