import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: '',
    severity: 'info',
    open: false,
};

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        showStatus: (state, action) => {
            const { message, severity } = action.payload;
            state.message = message;
            state.severity = severity || 'info';
            state.open = true;
        },
        closeStatus: (state) => {
            state.open = false;
        },
    },
});

export const { showStatus, closeStatus } = statusSlice.actions;

export default statusSlice.reducer;
