import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/amActions';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { showStatus } from '../../../../redux/slices/statusSlice'; // Importing showStatus action

// Styled Components
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#f0f2f5',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
}));

const LogoImage = styled('img')({
  width: '200px',
  marginBottom: '20px',
});

// Validation schema
const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = ({ onLoginSuccess, isModal = false, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for PWA installation prompt
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  // Install button event listener
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Save the event
      setShowInstallButton(true); // Show the install button
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt(); // Show the install prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null); // Reset the deferred prompt
      setShowInstallButton(false); // Hide the install button
    });
  };

  // Form handling
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await dispatch(loginUser(values.username, values.password));
        dispatch(showStatus({ message: 'Login successful!', severity: 'success' }));
        if (onLoginSuccess) {
          onLoginSuccess(response.payload.token);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Login failed:', error);
        const errorMessage = error.message.includes('401')
          ? 'Invalid credentials. Please try again.'
          : `Login failed: ${error.message}`;
        dispatch(showStatus({ message: errorMessage, severity: 'error' }));
      }
    },
  });

  return (
    <StyledBox>
      <StyledGrid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <LogoImage src="/LightTheme/akshaLogo.png" alt="Chakra ERP" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'left',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Welcome! Please login to your account.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                variant="standard"
                fullWidth
                id="username"
                name="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderBottom: '1px solid #ced4da',
                  },
                }}
              />
              <TextField
                variant="standard"
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderBottom: '1px solid #ced4da',
                  },
                  mb: 3,
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  width: '70%',
                  margin: '0 auto',
                  padding: '5px',
                }}
              >
                Login
              </Button>
              {isModal && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onClose}
                  sx={{
                    width: '70%',
                    margin: '10px auto 0',
                  }}
                >
                  Cancel
                </Button>
              )}
              {showInstallButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleInstallClick}
                  sx={{
                    width: '70%',
                    margin: '10px auto 0',
                  }}
                >
                  Install App
                </Button>
              )}
            </form>
          </Box>
        </Grid>
      </StyledGrid>
    </StyledBox>
  );
};

export default LoginPage;
