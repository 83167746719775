import React from 'react';

const PageLoader = () => {
  return (
    <div className="centerAbsolute">

    </div>
  );
};
export default PageLoader;
