import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import PageLoader from './components/PageLoader';
import { ThemeProvider } from '@mui/material/styles';
import DarkTheme from './themes/darkTheme';
import LightTheme from './themes/lightTheme';
import Sentinel from './sentinel';
import StatusDisplay from './components/status/StatusDisplay';
import './middleware/apolloInterceptor';

function App() {
  const [currentTheme, setCurrentTheme] = useState(LightTheme);

  // const toggleTheme = () => {
  //   setCurrentTheme(currentTheme === LightTheme ? DarkTheme : LightTheme);
  // };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={currentTheme}>
          <BrowserRouter>
          <StatusDisplay />
            <PageLoader />
            <Sentinel />
            {/* <Button onClick={toggleTheme}>Toggle Theme</Button> */}
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
