import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from '../redux/store'; // Import Redux store
import { showStatus } from '../redux/slices/statusSlice'; // Show status
import { showLoginModal } from '../redux/slices/modalSlice'; // Show login modal

// Middleware to add token to headers
export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '', // Add token to authorization header
    },
  };
});

// Error handling link for 401 responses and display status on screen
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError.message}`);

    // Handle 401 Unauthorized error (network error)
    if (networkError.statusCode === 401) {
      console.log('401 Unauthorized - Token expired or invalid, showing login modal...');

      // Dispatch the showStatus action to show the error on screen
      store.dispatch(showStatus({
        message: 'Session expired, please login again',
        severity: 'error',
      }));

      // Show the login modal instead of redirecting
      store.dispatch(showLoginModal());
    }
  }
});
