import { lazy, Suspense } from 'react';
//import { useSelector } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/client'; // Import your Apollo Client instance
import PageLoader from './components/PageLoader';
import AuthRouter from './router/AuthRouter';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from react-router-dom
import { checkTokenExpiry } from './middleware/tokenMiddleware'; // Import your token middleware

const AppHome = lazy(() => import('./components/Layout/AppHome'));

const DefaultApp = () => (
  <ApolloProvider client={client}>
    <Suspense fallback={<PageLoader />}>
      <AppHome />
    </Suspense>
  </ApolloProvider>
);

const Sentinel = () => {
  const token = localStorage.getItem('token') || null // Get the token from Redux store
  // const token = useSelector(state => state.AccessManagement.token)
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    // Check if the token is expired or not available using the token middleware
    if (token && checkTokenExpiry(token)) {
      // Token is expired or not available, navigate to login page
      localStorage.setItem('isLoggedIn', false);
      navigate('/login'); // Redirect to the login page
    }
  }, [token, navigate]);

  return (
    <div>
      {token ? (
        <DefaultApp />
      ) : (
        <AuthRouter />
      )}
    </div>

  );
};

export default Sentinel;
