import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isLoginModalOpen: false,
  },
  reducers: {
    showLoginModal: (state) => {
      state.isLoginModalOpen = true;
    },
    hideLoginModal: (state) => {
      state.isLoginModalOpen = false;
    },
  },
});

export const { showLoginModal, hideLoginModal } = modalSlice.actions;
export default modalSlice.reducer;
