import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { authLink, errorLink } from '../middleware/apolloInterceptor';

const serverUrl = process.env.REACT_APP_SERVER_GRAPHQL_URL;

const httpLink = createHttpLink({ uri: serverUrl });

const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink), // Chain the links
  cache: new InMemoryCache(),
});

export { client };
