import { combineReducers } from 'redux';
import notificationsReducer from './slices/notificationsSlice';
import menuReducer from '../redux/slices/menuSlice';
import amReducer from '../modules/AccessManagement/redux';
import statusSlice from './slices/statusSlice';
import modalSlice from './slices/modalSlice'

const rootReducer = combineReducers({
  AccessManagement: amReducer,
  notifications: notificationsReducer,
  menu: menuReducer,
  status:statusSlice,
  modal: modalSlice,
});

export default rootReducer;
